import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const trPointTypeEndpoint = "/transaction-point-type";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(trPointTypeEndpoint, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${trPointTypeEndpoint}/${id}`);
    },
    create(payload) {
        return Repository.post(`${trPointTypeEndpoint}`, payload);
    },
    update(payload,id) {
        // payload.append('_method','PUT');
        return Repository.put(`${trPointTypeEndpoint}/${id}`, payload);
    },
    delete(id) {
        return Repository.delete(`${trPointTypeEndpoint}/${id}`);
    }
}
