import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const formulaLoyalty = "/formula-loyalty";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(formulaLoyalty, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${formulaLoyalty}/${id}`);
    },
    create(payload) {

        return Repository.post(
            `${formulaLoyalty}`,
            payload
            // , { headers: { 'Content-Type': 'application/json'} }
        );
    },
    update(payload,id) {
        let data = {
            tiers : [],
            point_types : [],
        };
        _.forEach(payload, (v, k) => {
            if(v != null && v!= ''){
                data[k] = v;
            }
        });
        return Repository.put(`${formulaLoyalty}/${id}`, data);
    },
    delete(id) {
        return Repository.delete(`${formulaLoyalty}/${id}`);
    }
}
