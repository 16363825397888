const { escape } = require("querystring");

export default function createRequestOption(req) {
    var specialKey = {
        sort: "sort",
        // enumStages: "enumStages",
        // jejaksEnumStages: "jejaksEnumStages",
        // jejakTerakhirStages: "jejakTerakhirStages",
        // jejakTerakhirStagesIn: "jejakTerakhirStage.in",
        // tujuanRujukanId: "tujuanRujukanId",
        // perujukKategoriId: "perujukKategoriId",
        // tindakanParentId: "parentId.in",
        // tindakanParentNull: "parentId.specified",
        // idIn: "id.in",
        // createdDateGTE: 'createdDate.greaterOrEqualThan',
        // createdDateLTE: 'createdDate.lessOrEqualThan',
        // jejakTerakhirTimestampGTE: 'jejakTerakhirTimestamp.greaterOrEqualThan',
        // jejakTerakhirTimestampLTE: 'jejakTerakhirTimestamp.lessOrEqualThan'
    };
    var options = new URLSearchParams();

    if (req) {
        // Object.keys(req).forEach(key => {
        //     if (specialKey.hasOwnProperty(key)) {
        //         req[key] = Array.isArray(req[key]) ? req[key] : [req[key]];
        //         req[key].forEach(val => {
        //             options.append(specialKey[key], val);
        //         });
        //     } else {
        //         options.set(key, req[key]);
        //     }
        // });

        let getPairs = (obj, keys = []) =>
            Object.entries(obj).reduce((pairs, [key, value]) => {
                if (typeof value === 'object')
                    pairs.push(...getPairs(value, [...keys, key]));
                else
                    pairs.push([[...keys, key], value]);
                return pairs;
            }, []);

        let x = getPairs(req)
            .map(([[key0, ...keysRest], value]) =>
                `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
            .join('&');

        options = new URLSearchParams(x);
    }

    var request = {
        params: options
    }

    return request;
}

const encodeURLSearchParams = (queryObj, nesting = "") => {
    let queryString = "";

    const pairs = Object.entries(queryObj).map(([key, val]) => {
        // Handle a second base case where the value to encode is an array
        if (Array.isArray(val)) {
            return val
                .map(subVal => [nesting + key, subVal].map(escape).join("="))
                .join("&");
        } else if (typeof val === "object") {
            return encode(val, nesting + `${key}.`);
        } else {
            return [nesting + key, val].map(escape).join("=");
        }
    });
    return pairs.join("&");
}
