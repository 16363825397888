import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const members = "/patients";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(members, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${members}/${id}`);
    },
    create(payload) {
        return Repository.post(`${members}`, payload);
    },
    update(payload,id) {
        return Repository.put(`${members}/${id}`, JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    delete(id) {
        return Repository.delete(`${members}/${id}`);
    }
}
