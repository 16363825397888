<template v-if="tableFilter">
    <div class="col-sm-12" style="">
        <div class="col-lg-6 text-right" style="border: 1px solid #ebedf2;padding: 10px;">
            <div v-for="(val, idx) in filterData" :key="idx"
                class="input-group mb-3" style="margin-bottom: 0px !important;">
                <table-filter-field
                    :selectedPicker="val.inputField"
                    :table-filter="tableFilter"
                    :idSelectPicker="''+idx+''"
                    @changed-select-picker="onFieldChange" />
                <select-operator
                    :selectedPicker="val.inputOperator"
                    :operator="val.operatorList"
                    :idSelectPicker="''+idx+''"
                    @changed-select-picker="onOperatorChange" />
                <input
                    v-if="val.inputType !== 'date'"
                    v-bind:value="val.inputSearch"
                    v-on:input="val.inputSearch = $event.target.value"
                    :type="val.inputType"
                    class="form-control"
                    :placeholder="val.inputSearchPlaceholder"
                />
                <date-picker
                    v-if="val.inputType === 'date'"
                    ref="datePickerVue"
                    :changedValue.sync="val.inputSearch"
                    :id-date-picker="'inputSearch'"
                    :picker-value="val.inputSearch"
                    place-holder="DD/MM/YYYY"
                    start-date
                ></date-picker>

                <div v-if="!mutiple" class="input-group-prepend">
                    <button
                        type="button"
                        class="btn btn-default btn-bold btn-upper"
                        @click="clickSearch()"
                    >
                        <i class="fa fa-search"></i>
                    </button>
                    <button v-if="val.inputSearch != ''"
                        type="button"
                        class="btn btn-default btn-bold btn-upper"
                        @click="clickResetSearch()"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>

                <div v-if="mutiple && idx < (filterData.length - 1)" class="input-group-prepend">
                    <select style="width: 100px"
                        class="form-control"
                        data-live-search="true"
                        v-model="val.inputCondition"
                        :value="val.inputCondition"
                        :disabled="false"
                    >
                        <option value="and">And</option>
                        <option value="or">Or</option>
                    </select>
                </div>
                <div v-if="mutiple && idx==(filterData.length - 1)" class="input-group-prepend">
                    <button
                        type="button"
                        class="btn btn-default btn-bold btn-upper"
                        @click="clickAdd()"
                        style="width: 100px;"
                    >
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="form-group" style="margin-top: 10px;margin-bottom: 0px;">

                <button v-if="filterData[0].inputSearch != '' || filterData.length >= 2"
                    type="button"
                    class="btn btn-default btn-bold btn-upper"
                    @click="clickResetSearch()"
                >
                    <i class="fa fa-times"></i> Reset
                </button>
                 <button
                    type="button"
                    class="btn btn-default btn-bold btn-upper"
                    @click="clickSearch()"
                >
                    <i class="fa fa-search"></i> Cari Data
                </button>
            </div>
        </div>
        <div class="col-lg-5"></div>
    </div>
</template>
<script>
import SelectOperator from "./SelectOperator";
import TableFilterField from "./TableFilterField";
import DatePicker from "./../../../../components/_general/DatePicker";

export default {
    components: {
        DatePicker,
        TableFilterField,
        SelectOperator
    },
    data() {
        return {
            filterData : [
                {
                    inputType: '',
                    inputField: '',
                    inputOperator: '',
                    inputSearch: '',
                    inputSearchPlaceholder : "",
                    operatorList : this.operator,
                    inputCondition: 'and',
                }
            ],
            filterCount : this.count || 1,
        };
    },
    props: {
        idSelectPicker: { type: String },
        selectedPicker: { type: Number },
        tableFilter: { type: Array },
        operator: { type:Array },
        count: { type: Number },
        mutiple: { type: Boolean },
    },
    computed: {},
    mounted() {
        this.filterData = [
            {
                inputType: 'string',
                inputField: '',
                inputOperator: '',
                inputSearch: '',
                inputSearchPlaceholder : "",
                operatorList : this.operator,
                inputCondition: 'and',
            }
        ];
    },
    methods: {
        onFieldChange: function(dataSelected, id) {
            id = parseInt(id) || 0;
            this.filterData[id].inputOperator = '';
            this.filterData[id].inputSearch = '';
            this.filterData[id].inputField = dataSelected.key;
            this.filterData[id].inputType = dataSelected.type;
            this.setOperatorByFieldType(dataSelected.type, id);

        },
        onOperatorChange: function(dataSelected, id) {
            id = parseInt(id) || 0;
            this.filterData[id].inputOperator = dataSelected.id;
            switch(this.filterData[id].inputOperator){
                case 'in':
                    this.filterData[id].inputSearch = '';
                    this.filterData[id].inputSearchPlaceholder = 'value 1, value 2, value 2';
                    break;
                case 'between':
                    this.filterData[id].inputSearch = '';
                    this.filterData[id].inputSearchPlaceholder = 'value 1, value 2';
                    break;
                case 'and':
                case 'or':
                    this.filterData[id].inputSearch = '';
                    this.filterData[id].inputSearchPlaceholder = 'value 1, value 2';
                    break;
            }

        },
        clickSearch: function() {
            var vtx = this;
            let searchparams = this.filterData.map(function(data, idx){
                let condition = vtx.filterData[idx] ? vtx.filterData[idx].inputCondition : 'and';
                return {
                    name: data.inputField,
                    type: data.inputOperator,
                    value: data.inputSearch,
                    condition: condition,
                }
            });
            this.$emit("click-search", searchparams);
        },
        clickResetSearch: function() {
            this.filterData = [
                {
                    inputType: 'string',
                    inputField: '',
                    inputOperator: '',
                    inputSearch: '',
                    inputSearchPlaceholder : "",
                    operatorList : this.operator,
                    inputCondition: 'and',
                }
            ];

            this.$emit("click-reset", null);
        },
        setOperatorByFieldType(type, id){
            switch(type){
                case "string":
                    this.filterData[id].operatorList = [
                        {id:'equal',alias:'=',label:'Equals'},
                        {id:'stw',alias:'^',label:'Starts With'},
                        {id:'edw',alias:'$',label:'End With'},
                        {id:'contains',alias:'*',label:'Contains'},
                        // {id:'and',alias:'&',label:'And'},
                        {id:'or',alias:'|',label:'Or'},
                    ];
                    break;
                case "number":
                    this.filterData[id].operatorList = [
                        {id:'equal',alias:'=',label:'Equals'},
                        {id:'lt',alias:'<',label:'Lower Than'},
                        {id:'lte',alias:'<=',label:'Lower Than Or Equal To'},
                        {id:'gt',alias:'>',label:'Greater Than'},
                        {id:'gte',alias:'>=',label:'Greater Than or Equal To'},
                        // {id:'and',alias:'&',label:'And'},
                        {id:'or',alias:'|',label:'Or'},
                    ];
                    break;
                case "date":
                    this.filterData[id].operatorList = [
                        {id:'equal',alias:'=',label:'Equals'},
                        {id:'lt',alias:'<',label:'Lower Than'},
                        {id:'lte',alias:'<=',label:'Lower Than Or Equal To'},
                        {id:'gt',alias:'>',label:'Greater Than'},
                        {id:'gte',alias:'>=',label:'Greater Than or Equal To'},
                        // {id:'and',alias:'&',label:'And'},
                        {id:'or',alias:'|',label:'Or'},
                    ];
                    break;
            }
        },
        clickAdd: function(){
            // this.filterCount +=1;
            this.filterData.push({
                    inputType: '',
                    inputField: '',
                    inputOperator: '',
                    inputSearch: '',
                    inputSearchPlaceholder : "",
                    operatorList : this.operator,
                    inputCondition: 'and',
                });
        }
    }
};
</script>
