
// versi mandaya
import Repository from '../../repositories/Repository';
import paginateRepository from '../../repositories/AllPaginateRepository';

// import Repository from './BaseRepository';
// import paginateRepository from './PaginateRepository';

export default {
    // versi mandaya,
    get(req , resolve , reject){
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/user-patients', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getUserById(userId){
        return Repository.get('/patients/default/' + userId );
    },

    // // TODO remark, only for dev
    // get(req , resolve , reject) {
    //     new Promise((resolve, reject) => {
    //         paginateRepository.getAllPaginate('/user-patients', req, [], 0 , resolve ,reject);
    //     }).then((response) => {
    //         resolve(response);
    //     }).catch(error => {
    //         reject(error);
    //     });
    // },
    // getUserById(userId){
    //     return Repository.get('/patients/default/' + userId );
    // },
}
