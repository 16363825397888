import Repository from './BaseRepository';
import createRequestOption from './RequestUtil';
export default {
    async getAllPaginate(url, req, totalData = [], pages = 0, resolve, reject) {

        var parse = require('parse-link-header');
        const defaultConfigs = createRequestOption(req);
        await Repository.get(url, defaultConfigs).then(response => {
            const data = totalData.concat(response.data);
            const count = parseInt(response.headers["x-total-count"] || 0);
            resolve({ data, count });
        }).catch(error => {
            if (error.response) {
                reject(error.response.data)
            } else {
                reject(error.message);
            }
        })
    }
}
