import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const claimPointEndpoint = "/transaction-claim";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(claimPointEndpoint, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${claimPointEndpoint}/${id}`);
    },
    calculatePoint(payload){
        return Repository.post('calculate-point',payload);
    },
    create(payload) {
        return Repository.post(`${claimPointEndpoint}`, payload,{
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        });
    },
    update(payload,id) {
        // let data = new FormData();

        if (payload.attachments_delete && payload.attachments_delete.length){
            _.forEach(payload.attachments_delete, (atch_id) => {
                var del = payload.attachments.findIndex(x => x.id == atch_id);
                payload.attachments.splice(del, 1);
            });
        }

        payload.attachments = [];
        if (payload.attachments_new.length){
            payload.attachments = payload.attachments_new; // { ...payload.attachments, ...payload.attachments_new };
        }
        delete payload.attachments_new;

        return Repository.put(`${claimPointEndpoint}/${id}`, payload);
    },
    delete(id) {
        return Repository.delete(`${claimPointEndpoint}/${id}`);
    }
}
