import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const loyaltyTier = "/loyalty-tiers";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(loyaltyTier, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${loyaltyTier}/${id}`);
    },
    create(payload) {
        return Repository.post(`${loyaltyTier}`, payload);
    },
    update(payload,id) {
        return Repository.put(`${loyaltyTier}/${id}`, JSON.stringify(payload),{
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    delete(id) {
        return Repository.delete(`${loyaltyTier}/${id}`);
    }
}
