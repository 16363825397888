<template>
    <div class="kt-form__control filter-operator">
        <select style="width: 100px"
            @change="changedSelectPicker"
            class="form-control"
            v-bind:id="idSelectPicker"
            v-model="selected"
            :value="selectedValue"
            data-size="2"
            data-live-search="true"
            :disabled="disabledSelect"
        >
            <option v-for="item in data" :value="item.id" :key="item.id">{{
                item.label
            }}</option>
        </select>
    </div>
</template>

<script>
export default {
    inject: ["$validator"],
    data() {
        return {
            data: null,
            selected: "=",
            disabledSelect: true
        };
    },
    props: {
        idSelectPicker: { type: String },
        selectedPicker: { type: String },
        extraOperator: { type:Array },
        operator: { type:Array },

    },
    computed: {
        selectedValue() {
            this.selected = this.selectedPicker;
        }
    },
    watch: {
        operator: {
            immediate: true,
            handler (val, oldVal) {
                this.getData();
            }
        }
    },
    methods: {
        getSelectPicker: function() {
            this.getData();
        },
        changedSelectPicker: function(e) {
            if(e.isTrusted){
                this.$emit("update:changedValue", this.selected);
                this.$emit(
                    "changed-select-picker",
                    this.data.find(f => f.id == this.selected),
                    this.idSelectPicker
                );
            }
        },
        async getData() {
            let defaultOperator = [
                {id:'equal',alias:'=',label:'Equals'},
                {id:'lt',alias:'<',label:'Lower Than'},
                {id:'lte',alias:'<=',label:'Lower Than Or Equal To'},
                {id:'gt',alias:'>',label:'Greater Than'},
                {id:'gte',alias:'>=',label:'Greater Than or Equal To'},
                {id:'stw',alias:'^',label:'Starts With'},
                {id:'edw',alias:'$',label:'End With'},
                {id:'contains',alias:'*',label:'Contains'},
                // {id:'and',alias:'&',label:'And'},
                {id:'or',alias:'|',label:'Or'},
                {id:'conditional',alias:'()',label:'Conditional'},
                { id: "between", alias: "between", label: "Between" },
                { id: "in", alias: "in", label: "In" },
            ];

            if(this.operator && this.operator.length>0){
                this.data = this.operator;
            }
            else if(this.extraOperator && this.extraOperator.length>0){
                this.data = defaultOperator.concat(this.extraOperator);
            }
            else{
                this.data = defaultOperator;
            }

            this.disabledSelect = false;
        }
    },
    mounted() {
        this.getSelectPicker();
    }
};
</script>
