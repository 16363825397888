// import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';

export default {
    memberRegistration(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/member-registration-trend', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    couponUsed(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/coupon-used-trend', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    summaryClaimAmount(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/claim-summary-amount', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    summaryClaimPoint(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/claim-summary-point', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    customPoint(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/activity-custom-point', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    activityMember(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/activity-member', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    historyTierMember(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/dashboard/history-tier-member', req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
}
