<template>
  <div class="custom-dropdown kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" :class="{'is-invalid':hasError}">
    <select
        @change="changedSelectDropdown"
        :class="{'form-control':true,'is-invalid':hasError}"
        :name="idSelectDropdown"
        v-bind:id="idSelectDropdown"
        v-model="selected"
        multiple="multiple"
        v-validate="validateRule"
        :value="selectedValue"
        data-size="5"
        data-live-search="true"
        :disabled="isSelectDisabled"
    >
        <option
        v-for="item in dataResponse"
        :value="item.id"
        :key="item.id"
        >{{ item.id }} - {{ renderSelect(item) }}</option>
    </select>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";

export default {
  data() {
    return {
        dataResponse: null,
        selected: [],
        disabledSelect: true
    };
  },
  inject: ['$validator'],
  props: {
    isDisabled:{
      type:Boolean
    },
    validateRule:{
      type:String
    },
    hasError:{
      type:Boolean
    },
    renderSelect:{
        type:Function,
        required:true
    },
    repoName:{
      type:String,
      required:true
    },
    idSelectDropdown: { type: String },
    selectedDropdown: { type: Array }
  },
  watch:{
    selectedDropdown(val){
      if(val.length==0){
        $("#" + this.idSelectDropdown).val("");
        $("#" + this.idSelectDropdown).selectpicker("refresh");
      }
      // console.log('selectedDropdown',val)
    },
    dataResponse(val){
      this.changedSelectDropdown()
    }
  },
  computed: {
    isSelectDisabled(){
      return this.isDisabled ? this.isDisabled : this.disabledSelect
    },
    selectRepository(){
      return RepositoryFactory.get(this.repoName);
    },
    selectedValue() {
        this.selected = this.selectedDropdown;
    },
    selectedData(){
      let findResult = _.filter(this.dataResponse,(item, key)=>{
        return this.selected.includes(item.id);
      });
      return this.selected.length > 0 ? findResult : [];
    }
  },
  methods: {

    getDataById: function(id){
        return this.dataResponse.find(x => x.id == id);
    },
    getSelectDropdown: function() {
        this.getData();
    },
    changedSelectDropdown: function() {
        this.$emit("update:changedValue", this.selectedData, this.idSelectDropdown);
    },
    async getData() {
        var vx = this;
        // await UserRepository.get()
        new Promise((resolve, reject) => {
            // vx.repository.get({}, resolve, reject );
            vx.selectRepository.get({}, resolve, reject );
        })
        .then(response => {
            vx.disabledSelect = false;
            vx.dataResponse = response.data;
            $("#" + vx.idSelectDropdown).selectpicker();
            vx.$nextTick(() => {
                $("#" + vx.idSelectDropdown).selectpicker("refresh");
            });
        }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('User : ' + msg);
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    }
  },
  mounted() {
    this.getSelectDropdown();
  }
};
</script>

