<template>
    <div class="kt-form__control filter-field">
        <select style="width: 150px;"
            @change="changedSelectPicker"
            class="form-control"
            v-bind:id="idSelectPicker"
            v-model="selected"
            :value="selectedValue"
            data-size="5"
            data-live-search="true"
            :disabled="disabledSelect"
        >
            <option value="" disabled>Pilih Pencarian</option>
            <option
                v-for="item in tableFilter"
                :value="item.key"
                :key="item.key"
                >{{ item.title }}</option
            >
        </select>
    </div>
</template>

<script>
export default {
    //   name: "table-filter-field",
    inject: ["$validator"],
    data() {
        return {
            data: null,
            selected: '-1',
            disabledSelect: false
        };
    },
    props: {
        idSelectPicker: { type: String },
        selectedPicker: { type: String },
        tableFilter: { type: Array }
    },
    computed: {
        selectedValue() {
            this.selected = this.selectedPicker;
        }
    },
    methods: {
        changedSelectPicker: function() {
            this.$emit("update:changedValue", this.selected);
            this.$emit(
                "changed-select-picker",
                this.tableFilter.find(f => f.key == this.selected),
                this.idSelectPicker
            );
        }
    },
    mounted() {}
};
</script>
