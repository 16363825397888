import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const loyaltyTier = "/loyalty-tiers";

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            // if(req['sort'] == null){
            //     req['sort'] = 'id:asc';
            // }
            // paginateRepository.getAllPaginate(loyaltyTier, req, [], 0 , resolve ,reject);
            var res = {
                data: [
                    { id: 1, name: 'Transaksi Klaim' },
                    { id: 2, name: 'Penggunaan Kupon' },
                    { id: 3, name: 'Custom Poin' }
                ],
                count: 3
            };
            resolve(res);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
        return [
            { id: 1, name: 'Transaksi Klaim' },
            { id: 2, name: 'Penggunaan Kupon' },
            { id: 1, name: 'Custom Poin' }
        ];
    }
}