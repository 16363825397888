import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const couponsEndpoint = "/coupons";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(couponsEndpoint, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${couponsEndpoint}/${id}`);
    },
    async couponByCode(code) {
        return await Repository.get(`coupon-by-code/${code}`);
    },
    create(payload) {
        return Repository.post(`${couponsEndpoint}`, payload);
    },
    update(payload,id) {
        // let data = new FormData();
        // _.forEach(payload, (v, k) => {
        //     if(v != null){
        //         data.append(k, v);
        //     }
        // });
        // data.append('_method','PUT');
        // return Repository.post(`${couponsEndpoint}/${id}`, data);
        let data = {};
        _.forEach(payload, (v, k) => {
            if(v != null){
                data[k] = v;
            }
        });
        return Repository.put(`${couponsEndpoint}/${id}`, data);
    },
    delete(id) {
        return Repository.delete(`${couponsEndpoint}/${id}`);
    }
}
