import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const customPointEndpoint = "/custom-points";

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            if(req['sort'] == null){
                req['sort'] = 'id:asc';
            }
            paginateRepository.getAllPaginate(customPointEndpoint, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getById(id) {
        return Repository.get(`${customPointEndpoint}/${id}`);
    },
    create(payload) {
        // payload.append('_method','PUT');
        return Repository.post(`${customPointEndpoint}`, payload,{
            headers: {
            //   'Content-Type': 'multipart/form-data'
                'Content-Type': 'application/json;charset=UTF-8'
            }
        });
    },
    update(payload,id) {
        // payload.attachments=payload.attachments_new;
        // delete payload.attachments_new;
        // let data = new FormData();
        // _.forEach(payload, (v, k) => {
        //     if(v != null){
        //         if(k=='attachments'){
        //             if(v.length>0){
        //                 _.forEach(v,(file)=>{
        //                     data.append('attachments[]',file);
        //                 })
        //             }
        //         }else if(k=='attachments_delete'){
        //             if(v.length>0){
        //                 _.forEach(v,(att_id)=>{
        //                     data.append('attachments_delete[]',att_id);
        //                 })
        //             }
        //         }else{
        //             if(k=='members'){
        //                 _.forEach(v,(item)=>{
        //                     data.append('members[]',item);
        //                 })
        //             }
        //             else{
        //                 data.append(k, v);
        //             }
        //         }
        //     }
        // });
        // data.append('_method','PUT');
        // return Repository.post(`${customPointEndpoint}/${id}`, data);

        if (payload.attachments_delete && payload.attachments_delete.length) {
            _.forEach(payload.attachments_delete, (atch_id) => {
                var del = payload.attachments.findIndex(x => x.id == atch_id);
                payload.attachments.splice(del, 1);
            });
        }

        payload.attachments = [];
        if (payload.attachments_new.length){
            payload.attachments = payload.attachments_new; // { ...payload.attachments, ...payload.attachments_new };
        }
        delete payload.attachments_new;
        return Repository.put(`${customPointEndpoint}/${id}`, payload);
    },
    delete(id) {
        return Repository.delete(`${customPointEndpoint}/${id}`);
    }
}
