import MemberRepository from './MemberRepository';
import KategoriPromoRepository from './KategoriPromoRepository';
import LoyaltyTierRepository from './LoyaltyTierRepository';
import CouponsRepository from './CouponsRepository';
import TransactionCouponRepository from './TransactionCouponRepository';
import TransactionPointRepository from './TransactionPointRepository';
import ClaimPointRepository from './ClaimPointRepository';
import CustomPointRepository from './CustomPointRepository';
import TransactionPointTypeRepository from './TransactionPointTypeRepository';
import FormulaLoyaltyRepository from './FormulaLoyaltyRepository';
import HistoryPointTypeRepository from './HistoryPointTypeRepository';
import DashboardRepository from './DashboardRepository'
// for dev local
import PatientRepository from './PatientRepository';
import UserRepository from './UserRepository';

//for gallery
import GalleryRepository from './GalleryRepository';

// for dev mandaya
// import PatientRepository from './../../repositories/PatientRepository';


const repositories = {
    memberRepository: MemberRepository,
    kategoriPromo: KategoriPromoRepository,
    loyaltyTierRepository: LoyaltyTierRepository,
    patientRepository:PatientRepository,
    couponsRepository:CouponsRepository,
    transactionCouponRepository:TransactionCouponRepository,
    transactionPointRepository:TransactionPointRepository,
    customPointRepository:CustomPointRepository,
    claimPointRepository:ClaimPointRepository,
    transactionPointTypeRepository:TransactionPointTypeRepository,
    formulaLoyaltyRepository:FormulaLoyaltyRepository,
    historyPointTypeRepository: HistoryPointTypeRepository,
    dashboardRepository: DashboardRepository,
    userRepository: UserRepository,
    galleryRepository: GalleryRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
