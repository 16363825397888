import axios from "axios";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/gallery/api`;
const photo = "/photos";
const defaultConfigs = { params: { "size": 1000000 } };
let tenant = JSON.parse(localStorage.getItem("selectedTent"))

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers['Authorization'] = "Bearer " + localStorage.tokens;
    request.headers['X-TenantId'] = tenant.id;
    return request;
});

export default {
    get(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(categories, req, [], 0, resolve, reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    uploadPhoto(payload) {
        return instance.post(`${photo}`, payload, {
            headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: "Bearer " + localStorage.tokens,
                // "cache-control": "no-cache",
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
    }
}
