<template>
    <div>
        <v-server-table
            ref="tblRef"
            :name="table.name"
            :columns="table.columns"
            :options="table"
            @loading="onLoading"
            @loaded="onLoaded"
            @error="onError"
        >
            <template slot="beforeTable">
                <!-- <load-spinner v-if="isLoading || isRequestLoading" -->
                <load-spinner v-if="isRequestLoading"
                    :message="loadingMessage"
                ></load-spinner>
            </template>

            <div v-bind:key="key"
                v-for="(item, key) in table.customListFilter"
                :slot="'filter__' + key">
                <select-dropdown-multiple
                    :is-disabled="false"
                    :repo-name="item.repo"
                    @update:changedValue="onFilterSelected"
                    :ref="'filter__' + key"
                    :selected-dropdown="item.values"
                    :render-select="item.renderSelect"
                    :id-select-dropdown="key"
                    />
            </div>

            <template slot="actions" slot-scope="props">
                <button
                    v-if="permission.view"
                    @click="onClickView(props.row)"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="View"
                >
                    <i class="fa fa-eye"></i>
                </button>
                <button
                    v-if="permission.edit"
                    @click="onClickEdit(props.row)"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Edit"
                >
                    <i class="la la-pencil-square"></i>
                </button>
                <button
                    v-if="permission.delete"
                    @click="onClickDelete(props.row)"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Hapus"
                >
                    <i class="la la-trash"></i>
                </button>
            </template>
        </v-server-table>
    </div>
</template>

<script>
import { isNullOrUndefined } from "util";
import { ServerTable, Event } from "vue-tables-2";
import SelectDropdownMultiple from "./../SelectDropdownMultiple";
import TableFilter from "./TableFilter";
import { mapState, mapMutations, mapActions } from "vuex";
import LoadSpinner from "./../CustomLoadSpinner";
Vue.use(ServerTable, {}, false,'bootstrap4');
Vue.use(Event);

const getOperatorTypeParam = type => {
    if (type === "date") return "between";
    if (type === "number")  return "equal";
    if (type === "select")  return "equal";
    if (type === "multiselect")  return "in";
    return "contains";
};

const getValueParam = (type, value) => {
    if (type === "date") {
        return value.start + "," + value.end;
    }
    if (type === "select") {
        return value.start + "," + value.end;
    }
    if (type === "multiselect") {
        let valueIn = '';
        for(let ix in value){
            valueIn += value[ix].id;
            if(ix < (value.length - 1)){
                valueIn = valueIn + ",";
            }
        }
        return valueIn;
    }
    return value;
};

const defaultOptions = {
    //   sendInitialRequest: false,
    filterByColumn: false,
    toMomentFormat: "YYYY-MM-DD",
    dateFormat: "DD MMM YYYY",
    perPage: process.env.VUE_APP_PER_PAGE || 5,
    perPageValues: [0],
    texts:{
        filterBy: "Cari ...",
    },
    sortIcon: {
        base: "fa",
        is: "fa-sort",
        up: "fa-sort-up",
        down: "fa-sort-down "
    },
    requestAdapter(data) {
        let columnsFilter = this.columnsFilter;
        let customColumn = this.customColumn;
        let direction = (data.ascending == 1) ? "asc" : "desc";
        let orderBy = data.orderBy ? data.orderBy : "id";

        if (customColumn && customColumn[orderBy] != null) {
            orderBy = customColumn[orderBy];
        }

        var search = Object.keys(data.query).map(key => {
            let fiedlType = columnsFilter.find(f => f.key === key).type;
            let keyName = key;
            if (customColumn && customColumn[key] != null) {
                keyName = customColumn[key];
            }
            return {
                name: keyName,
                type: getOperatorTypeParam(fiedlType),
                value: getValueParam(fiedlType, data.query[key])
            };
        });

        if(data.queryListFilter){
            var searchCustom = Object.keys(data.queryListFilter).map(key => {
                let fiedlType = this.customListFilter[key].type;
                let keyName = key;
                if (customColumn && customColumn[key] != null) {
                    keyName = customColumn[key];
                }
                if(data.queryListFilter[key] && data.queryListFilter[key].length > 0){
                    return {
                        name: keyName,
                        type: getOperatorTypeParam(fiedlType),
                        value: getValueParam(fiedlType, data.queryListFilter[key])
                    };
                }
                return false;
            });
            search = search.concat(searchCustom);
        }

        return {
            search: search,
            page: data.page,
            size: data.limit,
            sort: orderBy + ":" + direction
        };
    },
    responseAdapter(responese) {
        return {
            data: responese.data,
            count: responese.count
        };
    }
};

export default {
    props: {
        isReloadData: { type: Boolean },
        tableOptions: { type: Object },
        exportColumns: { type: Array },
        repository: { type: Object },
        isChecked: { type: Boolean, default: false },
        permission: {
            type: Object,
            default: {
                edit: true,
                delete: true,
                view: false
            }
        }
    },
    components: {
        TableFilter,
        LoadSpinner,
        SelectDropdownMultiple
    },
    data() {
        var vtx = this;
        let tableOptions = { ...defaultOptions, ...vtx.tableOptions };

        if (typeof tableOptions.requestFunction !== "function") {
            tableOptions.requestFunction = params => {
                vtx.currentPage = params.page;
                return new Promise((resolve, reject) => {
                    this.fetchData({ params, resolve, reject });
                    this.refreshData(false);
                }).catch(error => {
                    vtx.isLoading = false;
                    var msg = error.message ? error.message : error;
                    toastr.error(msg);
                    throw new Error(msg);
                });
            };
        }

        if (tableOptions.columnsFilter) {
            tableOptions.filterByColumn = true;
            tableOptions.filterable = [];
            tableOptions.dateColumns = [];
            tableOptions.columnsFilter.map((columnsFilter, i) => {
                tableOptions.filterable.push(columnsFilter.key);
                if(columnsFilter.type === "date"){
                    tableOptions.dateColumns.push(columnsFilter.key);
                }
            });
        }

        if(tableOptions.customListFilter){
            vtx.queryListFilter = {};
            tableOptions.customFilters = ['queryListFilter'];
        }
        // console.log(tableOptions.customListFilter);

        return {
            isLoading: true,
            // loadingMessage: 'Memuat data ...',
            search: null,
            currentPage: 1,
            table: tableOptions
        };
    },
    computed: {
        ...mapState("Crud", {
            isRequestLoading: state => state.isLoading,
            loadingMessage: state => state.loadingMessage,
        })
    },
    methods: {
        ...mapActions({
            fetchData: "Crud/fetchData"
        }),
        ...mapMutations({
            refreshData: "Crud/REFRESH_TABLE",
        }),
        refreshTable: function() {
            this.$refs.tblRef.setPage(this.currentPage);
            this.refreshData(false);
        },
        onLoading: function(data) {
            this.isLoading = true;
        },
        onLoaded: function(data) {
            this.isLoading = false;
        },
        onError: function(data) {
            this.isLoading = false;
        },
        onClickEdit: function(data) {
            data.view_only = false;
            this.$emit("on-click-edit", data);
        },
        onClickDelete: function(data) {
            this.$emit("on-click-delete", data);
        },
        onClickView: function(data) {
            data.view_only = true;
            this.$emit("on-click-view", data);
        },
        onClickSearch: function(data) {
            this.search = null;
            if (!data.field || data.field == "") {
                toastr.warning("Pilihan Pencarian masih kosong");
                return;
            }
            if (data.field && data.operator) {
                this.search = [
                    { name: data.field, type: data.operator, value: data.value }
                ];
                this.$refs.tblRef.refresh();
            }
        },
        onClickResetSearch: function(data) {
            this.search = null;
            this.$refs.tblRef.refresh();
        },
        onFilterSelected(_value, _key){
            if(_value && _value.length > 0){
                this.queryListFilter[_key] = _value;
            }else{
                delete this.queryListFilter[_key];
            }
            Event.$emit('vue-tables.CRUD_TABLE.filter::queryListFilter', this.queryListFilter);
        },
    },
    mounted() {}
};
</script>
