import Repository from './BaseRepository';
import paginateRepository from './PaginateRepository';
const categories = "/promotion-categories";
const defaultConfigs = { params: { "size": 1000000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(categories, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    },
    getKategori(categoryId) {
        return Repository.get(`${categories}/${categoryId}`);
    },
    createKategori(payload) {
        return Repository.post(`${categories}`, payload);
    },
    updateKategori(payload,categoryId) {
        // payload.append('_method','PUT');
        // return Repository.post(`${categories}/${categoryId}`, payload);
        return Repository.put(`${categories}/${categoryId}`, payload);
    },
    deleteKategori(categoryId) {
        return Repository.delete(`${categories}/${categoryId}`);
    }
}
